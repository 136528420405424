<template>
  <b-row>
    <b-col sm="12" md="3">
      <small class="mr-1">Tampilkan</small>
      <label>
        <b-form-select
          size="sm"
          v-model="ip.state.perPage"
          :options="ip.pageLengths"
        />              
      </label>
      <small class="ml-1">entri</small>
    </b-col>
    <b-col sm="12" offset-md="6" md="3" class="mb-1">
      <b-input-group>
        <b-form-input @input="ip.state.filter = search" v-model="search" type="search" placeholder="Cari..."></b-form-input>
        <b-input-group-append is-text>
          <b-icon :scale="0.8" icon="search"></b-icon>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col sm="12">
      <b-table
        ref="datatables"
        striped
        hover
        responsive
        :items="ip.items"
        :fields="ip.fields"
        :busy="ip.busy"
        :current-page="ip.state.currentPage"
        :per-page="ip.state.perPage"
        :filter="ip.state.filter"
        :filter-ignored-fields="ip.state.filterIgnoredFields"
        :filter-included-fields="ip.state.filterIncludedFields"
        :no-provider-paging="isLocal"
        :no-provider-sorting="isLocal"
        :no-provider-filtering="isLocal"
        sort-by="0"
        :sort-desc="false"
        api-url="v1/type_transactions"
      >
      <template #cell(action)="data">
        <b-button size="sm" variant="outline-primary"
        :to="
        {
          name:'companies-numbering.show',
          params:{
            id:id,
            number:data.item[2]
          }
        }
        ">
          <b-icon icon="folder"></b-icon>
        </b-button>
      </template>
      </b-table>
      <div v-if="ip.totalRows > 0">
        <b-row>
          <b-col sm="6">
            <b-pagination
              v-model="ip.state.currentPage"
              :per-page="ip.state.perPage"
              :total-rows="ip.totalRows"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BTable,BPagination,BInputGroup,BFormInput,BInputGroupAppend,BIcon,BButton,BFormSelect } from 'bootstrap-vue'
import ItemsProvider from 'bvtnet-items-provider'
import axios from '@axios'

export default {
  data(){
    const fields = {
      0:{sortable: true, searchable: true, label:'No.'},
      1:{sortable: true, searchable: true, label:'Nama Transaksi'},
      2:{sortable: false, searchable: false, label:'',key:'action'},
    }
    const ip = new ItemsProvider({axios: axios, fields: fields})
    return {
      id: this.$route.params.id,
      ip: ip,
      isLocal: false,
      search:'',
    }
  },
  components:{
    BTable,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BIcon,
    BButton,
    BFormSelect
  }
}
</script>

<style>

</style>